import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const GetExtensionImages = () => {

    // Don't forget to set the size of your image in
    // fluid(maxWidth: 300, quality: 100) {
    const data = useStaticQuery(graphql`
    query {
          masonryData: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "masonryFolder"}, size: {}}
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    src
                    presentationHeight
                  }
                  original {
                    height
                    width
                    src
                  }
                }
              }
            }
          }
          extensionData: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "extensionsFolder"}, size: {}}
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    src
                    presentationHeight
                  }
                  original {
                    height
                    width
                    src
                  }
                }
              }
            }
          }
          gardenData: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "gardensFolder"}, size: {}}
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    src
                    presentationHeight
                  }
                  original {
                    height
                    width
                    src
                  }
                }
              }
            }
          }
        gardenRoomsData: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "gardenRoomsFolder"}, size: {}}
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    src
                    presentationHeight
                  }
                  original {
                    height
                    width
                    src
                  }
                }
              }
            }
          }
        miscData: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "miscFolder"}, size: {}}
          ) {
            edges {
              node {
                childImageSharp {
                  fluid {
                    src
                    presentationHeight
                  }
                  original {
                    height
                    width
                    src
                  }
                }
              }
            }
          }
    }`);

    return data;
};
